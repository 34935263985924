@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
a.client-link-base {
	text-decoration: none;
	cursor: pointer;

	&.page-link {
		color: #6629b7;
		font-weight: bold;
	}
}
